<template>
    <section class="section container columns is-centered pt-4 ">
        <div class="column  is-half is-mobile ">

            <div class="field columns has-text-centered">
                <div v-show="isMobile" class="column ">
                    <label class="label pt-2 has-text-center">Nombre </label>
                </div>
                <div v-show="!isMobile" class="column">
                    <label class="label pt-2 has-text-right">Nombre </label>
                </div>
                <div class="control column ">
                    <input v-model="nombre" name="nombre" class="input input-size" type="text" readonly>
                </div>
            </div>

            <div class="field  columns has-text-centered">
                <div v-show="isMobile" class="column ">
                    <label class="label pt-2 has-text-center">Correo electrónico </label>
                </div>
                <div v-show="!isMobile" class="column ">
                    <label class="label pt-2 has-text-right">Correo electrónico </label>
                </div>
                <div class="control column">
                    <input v-model="email" name="email" class="input input-size" type="text" readonly>
                </div>
            </div>

            <div v-show="contraseña_activa" class="field columns has-text-centered">
                <div v-show="isMobile" class="column ">
                    <label class="label pt-2 has-text-center">Contraseña vieja </label>
                </div>
                <div v-show="!isMobile" class="column ">
                    <label class="label pt-2 has-text-right">Contraseña vieja </label>
                </div>
                <div class="column">
                    <div class="field has-addons is-justify-content-center">
                        <div class="control">
                            <input v-model="old_password" name="old_password" class="input" :class="{'pr-5':isMobile}" :type="old_passwordFieldType">
                            <p v-show="message_error.old_password != ''" class="help is-danger">{{message_error.old_password}}</p>
                        </div>
                        <div class="control">
                            <button class="button " type="password" @click="switchVisibility(1)">
                                <span class="icon is-small is-right" >
                                    <i class="fas fa-eye"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>


            </div>

            <div v-show="contraseña_activa" class="field  columns has-text-centered">
                <div v-show="isMobile" class="column ">
                    <label class="label pt-2 has-text-center">Nueva contraseña </label>
                </div>
                <div v-show="!isMobile" class="column ">
                    <label class="label pt-2 has-text-right">Nueva contraseña </label>
                </div>
                <div class="column">
                    <div class="field has-addons is-justify-content-center">
                        <div class="control">
                            <input v-model="password" name="password" class="input" :type="passwordFieldType" :class="{'pr-5':isMobile}">
                            <p v-show="message_error.password != ''" class="help is-danger">{{message_error.password}}</p>
                        </div>
                        <div class="control">
                            <button class="button " type="password" @click="switchVisibility(2)">
                                <span class="icon is-small is-right" >
                                    <i class="fas fa-eye"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div v-show="contraseña_activa" class="field  columns has-text-centered">
                <div v-show="isMobile" class="column ">
                    <label class="label pt-2 has-text-center">Confirmar contraseña </label>
                </div>
                <div v-show="!isMobile" class="column ">
                    <label class="label pt-2 has-text-right">Confirmar contraseña </label>
                </div>
                <div class="column">
                    <div class="field has-addons is-justify-content-center">
                        <div class="control">
                            <input v-model="password_confirmation" name="password_confitmation" class="input" :class="{'pr-5':isMobile}" :type="password_confirmationFieldType">
                            <p v-show="message_error.password_confirmation != ''" class="help is-danger">{{message_error.password_confirmation}}</p>
                        </div>
                        <div class="control">
                            <button class="button " type="password" @click="switchVisibility(3)">
                                <span class="icon is-small is-right" >
                                    <i class="fas fa-eye"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>


            </div>

            <div v-show="!isMobile" class="field columns ">

                <div v-show="!contraseña_activa" class="control column has-text-right">
                    <button class="button is-success " style="width: 15.6rem;" @click="activar_contraseña()">
                        Editar contraseña
                    </button>
                </div>

                <div v-show="contraseña_activa" class="column  has-text-right ">
                    <button class="button  has-text-right" style=" background-color: #DD2923 ; color:white;" @click="volver">
                        Cancelar
                    </button>
                </div>
                <div v-show="contraseña_activa && !subiendo" class="control column   is-flex-grow-0 ">
                    <button class="button  is-success " style="width: 17.1rem;" @click="validar()">
                        Confirmar cambio
                    </button>
                </div>
                <div v-show="contraseña_activa && subiendo" class="control column   is-flex-grow-0 ">
                    <button class="button  is-success is-loading" style="width: 17.1rem;"></button>
                </div>

            </div>

            <div v-show="isMobile" class="field columns has-text-centered">
                <div v-show="!contraseña_activa" class="control column">
                    <button class="button is-success " style="width: 15.6rem;" @click="activar_contraseña()">
                        Editar contraseña
                    </button>
                </div>

                <div v-show="contraseña_activa && !subiendo" class="control pb-0 column is-flex-grow-0 ">
                    <button class="button is-success" @click="validar()">
                        Confirmar cambio
                    </button>
                </div>
                <div v-show="contraseña_activa && subiendo" class="control column is-flex-grow-0 ">
                    <button class="button is-success is-loading"></button>
                </div>
                <div v-show="contraseña_activa" class="column">
                    <button class="button" style="background-color: #DD2923 ; color:white" @click="volver">
                        Cancelar
                    </button>
                </div>
            </div>
            
        </div>
    </section>

</template>

<script>
import { GraphQLClient } from 'graphql-request'
import { ref } from '@vue/reactivity'
import store from '@/store';
import { useRoute, useRouter } from 'vue-router';
import { inject } from '@vue/runtime-core';

export default {

    created(){
        this.perfil()
    },

    setup(){

        const router = useRouter()

        const isMobile = inject('isMobile')
        const nombre = ref('')
        const email = ref('')
        const contraseña_activa = ref(false)
        const old_password = ref('')
        const password = ref('')
        const password_confirmation = ref('')
        const subiendo = ref(false)

        const old_passwordFieldType = ref('password')
        const passwordFieldType = ref('password')
        const password_confirmationFieldType = ref('password')
        const endpoint = store.state.url_backend

        const error = ref(false)
        const message_error = ref({password: '', password_confirmation: '', old_password: ''})

        const perfil = () => {
            // const endpoint = "http://45.70.8.41:88/graphql" // guardamos la url del playground
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego

            client.rawRequest(/* GraphQL */ `
                query{
                    me{
                        id,
                        name,
                        email
                    }
                }`,
                {},
                {
                    authorization: `Bearer ${ localStorage.getItem('user_token') }`
                })
                .then(data => {
                    // console.log(data.data.me)
                    let info = data.data.me
                    nombre.value = info.name
                    email.value = info.email
                }).catch(error => {
                    // console.log(error.response)
                })
        }

        const activar_contraseña = () => {
            contraseña_activa.value = true
        }

        const switchVisibility = (opc) => {
            switch (opc) {
                case 1:
                    old_passwordFieldType.value = old_passwordFieldType.value === 'password' ? 'text' : 'password'
                    break;

                case 2:
                    passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password'
                    break;

                case 3:
                    password_confirmationFieldType.value = password_confirmationFieldType.value === 'password' ? 'text' : 'password'
                    break;

                default:
                    break;
            }
        }

        const volver = () => {
            contraseña_activa.value = false
        }

        const update_password = () => {
            // const endpoint = "http://45.70.8.41:88/graphql" // guardamos la url del playground
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego

            client.rawRequest(/* GraphQL */ `
                mutation($old_password: String!, $password: String!, $password_confirmation: String!){
                    updatePassword(input: {
                        old_password: $old_password,
                        password: $password,
                        password_confirmation: $password_confirmation
                    }){
                        status,
                        message
                    }
                }`,
                {
                    old_password: old_password.value,
                    password: password.value,
                    password_confirmation: password_confirmation.value
                },
                {
                    authorization: `Bearer ${ localStorage.getItem('user_token') }`

                }).then(data => {
                    // console.log(data)
                    router.push({name: 'Tablero'})
                }).catch(error => {
                    // console.log(error.response)
                    subiendo.value = false
                    message_error.value.old_password = 'La contraseña no es correcta'
                })
        }

        const validar = () => {
            subiendo.value = true
            // console.log('hola')
            error.value = false
            message_error.value.password = ''
            message_error.value.password_confirmation = ''
            message_error.value.old_password = ''


            if ((password.value != '') && (password_confirmation.value != '')) {
                if (password_confirmation.value != password.value) {
                    error.value = true
                    message_error.value.password_confirmation = 'Las contraseñas deben ser iguales'
                }
            } else {
                if (password.value == '') {
                    error.value = true
                    message_error.value.password = 'el campo contraseña no puede estar vacio'
                }
                if (password_confirmation.value == '') {
                    error.value = true
                    message_error.value.password_confirmation = 'el campo confirmar contraseña no puede estar vacio'
                }
            }

            if (!error.value) {
                update_password()
            } else {
                subiendo.value = false
            }
        }

        return{
            isMobile,
            nombre,
            email,
            contraseña_activa,
            old_password,
            password,
            password_confirmation,
            message_error,
            subiendo,
            old_passwordFieldType,
            passwordFieldType,
            password_confirmationFieldType,

            perfil,
            activar_contraseña,
            update_password,
            validar,
            volver,
            switchVisibility
        }
    }
}
</script>

<style scoped>
.input-size{
    width: 20.3rem    
}

@media screen and (min-width: 800px) {
    .input-size{
    width: 100%;
  }
}
</style>